export type Conference = {
  confId?: string;
  name?: string;
  date?: string;
  location?: string;
  archived?: boolean;
  prizeName?: string;
  prizeDesc?: string;
  prizeImgUrl?: string;
  prizeWinners?: string[];
  interests?: Interest[];
  sendToHubSpot?: boolean;
  sendRegistrationEmail?: boolean;
};

export type Interest = {
  label?: string;
  value?: InterestType;
};

export enum InterestType {
  DEVELOPMENT = 'Development',
  TRAINING = 'Training',
  CLOUD = 'Cloud',
  COACHING = 'Coaching',
}
