import React, { useEffect } from 'react';

const LinkedInFollowButton = ({ companyId }: { companyId: string }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://platform.linkedin.com/in.js';
    script.type = 'text/javascript';
    script.innerHTML = 'lang: en_US';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <script type="IN/FollowCompany" data-id={companyId}/>
  );
};

export default LinkedInFollowButton;