import { RestService } from './RestService';

export const ConferenceService = {
  getAll: async () => {
    return await RestService.get('/conference/list');
  },
  get: async (confId) => {
    return await RestService.get(`/conference/${confId}`);
  },
  getPublic: async (confId) => {
    return await RestService.get(`/public/conference/${confId}`);
  },
  create: async (data) => {
    const response = await RestService.post('/conference/new', data);
    return response.success;
  },
  update: async (data) => {
    const response = await RestService.put(`/conference/${data.confId}`, data);
    return response.success;
  },
  archive: async (confId) => {
    const response = await RestService.post(`/conference/archive/${confId}`);
    return response.success;
  },
  delete: async (confId) => {
    const response = await RestService.delete(`/conference/${confId}`);
    return response.success;
  },
  sendTestEmail: async (confId, email) => {
    await RestService.post(`/conference/send-test-email/${confId}`, { email });
  },
};
