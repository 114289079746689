import { RestService } from './RestService';

export const AttendeeService = {
  getAllByConfId: async (confId) => {
    const data = await RestService.get('/attendee/list/' + confId);
    return data || [];
  },
  get: async (id) => {
    return await RestService.get(`/attendee/${id}`);
  },
  create: async (data) => {
    return await RestService.post('/public/attendee/new', data);
  },
  update: async (data) => {
    return await RestService.put(`/attendee/${data.email}/${data.confId}`, data);
  },
  claimPrize: async (data) => {
    const response = await RestService.put(
      `/attendee/claimPrize/${data.email}/${data.confId}`,
      data,
    );
    return response.success;
  },
  eligibility: async (data) => {
    return await RestService.put(`/attendee/eligibility/${data.email}/${data.confId}`, data);
  },
};
