import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';
import HeaderBar from './HeaderBar';

const PrivateLayout = () => {
  return (
    <>
      <HeaderBar />
      <Container>
        <Outlet />
      </Container>
    </>
  );
};

export default PrivateLayout;
