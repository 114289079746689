import axios from 'axios';

// eslint-disable-next-line no-undef
const URL = process.env.REACT_APP_API_SERVER_URL;
const CONFIG = { withCredentials: true };

export const RestService = {
  get: async (endpoint) => {
    try {
      const response = await axios.get(`${URL}${endpoint}`, CONFIG);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  },
  post: async (endpoint, data) => {
    try {
      const response = await axios.post(`${URL}${endpoint}`, data, CONFIG);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return { success: false, error: 'Failed to save' };
    }
  },
  put: async (endpoint, data) => {
    try {
      const response = await axios.put(`${URL}${endpoint}`, data, CONFIG);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  },
  delete: async (endpoint) => {
    try {
      const response = await axios.delete(`${URL}${endpoint}`, CONFIG);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  },
};
