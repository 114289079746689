import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AuthProvider, RequireAuth } from './context/Auth';
import React from 'react';
import PublicLayout from './components/PublicLayout';
import LoginPage from './pages/public/LoginPage';
import NotFoundPage from './pages/public/NotFoundPage';
import UnauthorizedPage from './pages/public/UnauthorizedPage';
import PrivateLayout from './components/PrivateLayout';
import { createTheme, ThemeProvider } from '@mui/material';
import RegisterAttendeePage from './pages/public/RegisterAttendeePage';
import ConferencesPage from './pages/private/ConferencesPage';
import ConferencePage from './pages/private/ConferencePage';
import ConferenceFormPage from './pages/private/ConferenceFormPage';
import { CookiesProvider } from 'react-cookie';
import ConferenceLandingPage from './pages/public/ConferenceLandingPage';
import ConferencePickWinnerPage from './pages/private/ConferencePickWinnerPage';
import RegisterCompletePage from './pages/public/RegisterCompletePage';
import QRCodeGeneratorPage from './pages/private/QRCodeGeneratorPage';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#141d3e',
    },
    secondary: {
      main: '#ff3131',
    },
  },
  typography: {
    fontFamily: ['Raleway', 'sans-serif'].join(','),
  },
});

const App = () => {
  return (
    <CookiesProvider>
      <AuthProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Routes>
              {/*Public*/}
              <Route element={<PublicLayout />}>
                <Route path="/" element={<LoginPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/landing/:id" element={<ConferenceLandingPage />} />
                <Route path="/register/:id" element={<RegisterAttendeePage />} />
                <Route path="/register/complete" element={<RegisterCompletePage />} />
                <Route path="/unauthorized" element={<UnauthorizedPage />} />
                <Route path="/notFound" element={<NotFoundPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Route>

              {/*Private*/}
              <Route element={<RequireAuth />}>
                <Route element={<PrivateLayout />}>
                  <Route path="/qrcodegenerator" element={<QRCodeGeneratorPage />} />
                  <Route path="/conferences" element={<ConferencesPage />} />
                  <Route path="/conference/:id" element={<ConferencePage />} />
                  <Route path="/conference/edit/:id" element={<ConferenceFormPage />} />
                  <Route path="/conference/new" element={<ConferenceFormPage />} />
                  <Route path="/conference/pickWinner/:id" element={<ConferencePickWinnerPage />} />
                </Route>
              </Route>
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </AuthProvider>
    </CookiesProvider>
  );
};

export default App;
