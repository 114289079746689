import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Checkbox, useTheme } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';
import logo from '../../lib/circle_logo.png';
import FormControlLabel from '@mui/material/FormControlLabel';

const QRCodeGeneratorPage = () => {
  const theme = useTheme();
  const [url, setUrl] = useState<string>('');
  const [useLogoAndColors, setUseLogoAndColors] = useState<boolean>(true);
  const [urlInput, setUrlInput] = useState<string>('');

  const generate = async () => {
    setUrl(urlInput.trim());
  };

  return (
    <Box
      sx={{
        marginTop: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Grid container spacing={2} alignItems="center" sx={{ mb: 10 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="url"
            fullWidth
            id="url"
            label="URL"
            autoFocus
            onChange={(e) => setUrlInput(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={useLogoAndColors}
                onChange={(e) => setUseLogoAndColors(e.target.checked)}
              />
            }
            label="Use Sketch Colors and Logo"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button variant="contained" onClick={generate}>
            Generate QR Code
          </Button>
        </Grid>
      </Grid>
      {url && (
        <QRCode
          fgColor={useLogoAndColors ? theme.palette.primary.main : undefined}
          eyeColor={useLogoAndColors ? theme.palette.secondary.main : undefined}
          value={url || ''}
          size={500}
          logoImage={useLogoAndColors ? logo : undefined}
        />
      )}
    </Box>
  );
};

export default QRCodeGeneratorPage;
