import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ConferenceService } from '../../services/ConferenceService';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Conference, Interest, InterestType } from '../../types/Conference';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';

const DEFAULT_INTERESTS = [
  { label: 'Agile Advisory', value: InterestType.COACHING },
  { label: 'Leadership Advisory', value: InterestType.COACHING },
  { label: 'Cloud Enablement', value: InterestType.CLOUD },
  { label: 'Development', value: InterestType.DEVELOPMENT },
  { label: 'Training', value: InterestType.TRAINING },
];

const ConferenceFormPage = () => {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [nameError, setNameError] = useState<string | null>(null);
  const [saveError, setSaveError] = useState(false);
  const [conference, setConference] = useState<Conference>({ interests: DEFAULT_INTERESTS });
  const [newInterest, setNewInterest] = useState<Interest | undefined>();
  const [interests, setInterests] = useState<Interest[]>(DEFAULT_INTERESTS);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setConference({ ...conference, prizeImgUrl: reader.result as string });
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    (async () => {
      if (id) {
        const c = await ConferenceService.get(id);
        if (c) {
          setDate(dayjs(c.date));
          setConference(c);
          setInterests(c.interests || []);
        }
      }
    })();
  }, [id]);

  const handleSubmit = async () => {
    if (!conference.name || conference.name === '') {
      setNameError('Name is required');
    } else {
      setSaveError(false);
      const newDate = date?.format('YYYY-MM-DD');
      if (id) {
        const newConference = { ...conference, interests, date: newDate };
        if (await ConferenceService.update(newConference)) {
          navigate(-1);
        }
      } else {
        const success = await ConferenceService.create({
          name: conference.name,
          date: newDate,
          location: conference.location,
          prizeName: conference.prizeName,
          prizeDesc: conference.prizeDesc,
          prizeImgUrl: conference.prizeImgUrl,
          interests,
          sendToHubSpot: conference.sendToHubSpot,
          sendRegistrationEmail: conference.sendRegistrationEmail
        });
        if (success) {
          navigate(-1);
        } else {
          setSaveError(true);
        }
      }
    }
  };

  const handleDeleteInterest = (index: number) => {
    setInterests((values) => values.filter((_, i) => i !== index));
  };

  const handleAddInterest = () => {
    if (newInterest && !_.isEmpty(newInterest.label) && !_.isEmpty(newInterest?.value)) {
      setInterests((prevState: Interest[]) => [...prevState, newInterest]);
      setNewInterest(undefined);
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setSaveError(false);
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [saveError]);

  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <IconButton size={'large'} onClick={() => navigate(-1)}>
            <ArrowBackIcon color={'secondary'} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="name"
            label="Name"
            autoFocus
            error={nameError !== null}
            helperText={nameError}
            onFocus={() => setNameError(null)}
            onChange={(e) => setConference({ ...conference, name: e.target.value })}
            value={conference?.name || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="location"
            fullWidth
            id="location"
            label="Location"
            value={conference?.location || ''}
            onChange={(e) => setConference({ ...conference, location: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Date" value={date} onChange={(newValue) => setDate(newValue)} />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="prizeName"
            fullWidth
            id="prizeName"
            label="Prize Name"
            value={conference?.prizeName || ''}
            onChange={(e) => setConference({ ...conference, prizeName: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="prizeDesc"
            fullWidth
            id="prizeDesc"
            label="Prize Description"
            value={conference?.prizeDesc || ''}
            onChange={(e) => setConference({ ...conference, prizeDesc: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <Box component="section" sx={{ p: 2, border: '1px solid grey' }}>
            <Typography variant={'h5'}>Prize Image</Typography>
            <Typography variant={'body2'}>Upload a prize image</Typography>
            <input type="file" accept="image/*" onChange={handleImageUpload} />
            {conference.prizeImgUrl && (
              <>
                <Typography hidden variant={'body2'}>{conference.prizeImgUrl}</Typography>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Typography variant="h6">Image Preview:</Typography>
                  <IconButton onClick={() => setConference({...conference, prizeImgUrl: ''})}><DeleteIcon color="error"/></IconButton>
                </div>
                <img src={conference.prizeImgUrl} alt="Uploaded" height={250} />
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box component="section" sx={{ p: 2, border: '1px solid grey' }}>
            <Grid container spacing={2} columnSpacing={2}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Interest Label"
                  value={newInterest?.label || ''}
                  onChange={(e) => {
                    const label = e.target.value;
                    setNewInterest((prevState) =>
                      prevState ? { ...prevState, label } : { label },
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Prospect Type</InputLabel>
                  <Select
                    value={newInterest?.value || ''}
                    label="Prospect Type"
                    defaultValue={InterestType.COACHING}
                    onChange={(e) => {
                      const value = e.target.value as InterestType;
                      setNewInterest((prevState) =>
                        prevState
                          ? {
                            ...prevState,
                            value
                          }
                          : { value },
                      );
                    }}>
                    <MenuItem value={InterestType.COACHING}>Coaching</MenuItem>
                    <MenuItem value={InterestType.TRAINING}>Training</MenuItem>
                    <MenuItem value={InterestType.CLOUD}>Cloud</MenuItem>
                    <MenuItem value={InterestType.DEVELOPMENT}>Development</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <IconButton onClick={handleAddInterest}>
                  <AddIcon color="secondary" />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {interests.map((data: Interest | string, index: number) => {
                    if ((typeof data) === 'string') {
                      return null;
                    } else {
                      return (
                        <Grid item key={index}>
                          <Chip
                            sx={{
                              '& .MuiChip-deleteIcon:hover': {
                                color: theme.palette.secondary.main,
                              },
                            }}
                            label={`${(data as Interest).label} (${(data as Interest).value})`}
                            onDelete={() => handleDeleteInterest(index)}
                          />
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <Box component="section" sx={{ p: 2, border: '1px solid grey' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!conference.sendToHubSpot}
                  onChange={(e) => setConference({ ...conference, sendToHubSpot: e.target.checked })}
                />
              }
              label="Send to HubSpot"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!conference.sendRegistrationEmail}
                  onChange={(e) => setConference({ ...conference, sendRegistrationEmail: e.target.checked })}
                />
              }
              label="Send Registration Email"
            />
          </Box>
        </Grid>
      </Grid>
      <Button onClick={handleSubmit} variant="contained" sx={{ mt: 3, mb: 2 }}>
        Submit
      </Button>
      {saveError && (
        <Alert severity="error" variant="standard" className="alert">
          Failed to save!
        </Alert>
      )}
    </Box>
  );
};

export default ConferenceFormPage;
