import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';

const UnauthorizedPage = () => (
  <div>
    <Typography variant="h5">Unauthorized page</Typography>
    <Typography variant="body1">{'You don\'t have permission to access this page.'}</Typography>
    <Typography variant="body1">
      <Link to="/login">Go back to login.</Link>
    </Typography>
  </div>
);

export default UnauthorizedPage;
