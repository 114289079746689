import React, { useEffect, useState } from 'react';
import { ConferenceService } from '../../services/ConferenceService';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Grid from '@mui/material/Grid';
import { AttendeeService } from '../../services/AttendeeService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Conference, Interest } from '../../types/Conference';
import { Attendee } from '../../types/Attendee';
import Button from '@mui/material/Button';
import AttendeesTable from '../../components/AttendeesTable';
import TextField from '@mui/material/TextField';

const ConferencePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [conference, setConference] = useState<Conference | null | undefined>();
  const [attendees, setAttendees] = useState<Attendee[]>([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [testEmail, setTestEmail] = useState('');

  useEffect(() => {
    (async () => {
      const c = await ConferenceService.get(id);
      if (c) {
        setConference(c);
        const attendeesList = await AttendeeService.getAllByConfId(id);
        setAttendees(attendeesList);
      } else {
        setConference(null);
      }
    })();
  }, [id]);

  const handleDelete = async () => {
    const success = await ConferenceService.delete(id);
    if (success) {
      navigate(-1);
    }
  };

  const sendTestEmail = async () => {
    await ConferenceService.sendTestEmail(id, testEmail);
  };

  const archive = async () => {
    const success = await ConferenceService.archive(id);
    if (success) {
      navigate(-1);
    }
  };

  if (conference === undefined) return <Typography>Loading</Typography>;
  if (conference === null) return <Typography>Conference Not Found</Typography>;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon color={'secondary'} />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h4">{conference.name}</Typography>
            </Grid>
            <Grid item>
              <Typography color={'secondary'}>{conference.archived && 'ARCHIVED'}</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/landing/' + id)}>
                Landing Site
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={1}>
          {!conference.archived && (
            <IconButton onClick={() => navigate('/conference/edit/' + id)}>
              <EditIcon color="secondary" />
            </IconButton>
          )}
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1">Location:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{conference.location}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1">Date:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{conference.date}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1">Prize Name:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{conference.prizeName}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1">Prize Description:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{conference.prizeDesc}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1">Prize Image:</Typography>
        </Grid>
        <Grid item xs={8}>
          {conference.prizeImgUrl && (
            <img height={150} src={conference.prizeImgUrl} alt={conference.prizeName} />
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">Interests:</Typography>
        </Grid>
        <Grid item xs={8}>
          {(conference.interests || []).map((i: Interest | string, index: number) => {
            return (
              <Typography variant="body2" key={index}>
                {typeof i === 'string' ? i : `${i.label} (${i.value})`}
              </Typography>
            );
          })}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">Send To HubSpot:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{conference.sendToHubSpot ? 'Yes' : 'No'}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">Send Registration Email:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{conference.sendRegistrationEmail ? 'Yes' : 'No'}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container direction="row" alignItems="flex-end" spacing={1}>
            <Grid item>
              <Typography variant="h4" mt={3}>
                Attendees
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Grid container direction="row" alignItems="right">
            {!conference.archived && (
              <Grid item>
                <Button
                  color="secondary"
                  onClick={() => navigate('/conference/pickWinner/' + id)}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}>
                  Pick Winner
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <AttendeesTable conference={conference} attendees={attendees} setAttendees={setAttendees} />
      <Grid container direction={'column'} spacing={5} mt={10} mb={10} justifyContent={'center'}>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={() => setArchiveOpen(true)}>
            Archive Conference
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={() => setDeleteOpen(true)}>
            Delete Conference
          </Button>
        </Grid>
        <Grid item>
          <Grid container gap="1rem">
            <TextField
              name="email"
              id="email"
              label="Email"
              autoFocus
              onChange={(e) => setTestEmail(e.target.value)}
            />
            <Button variant="contained" color="secondary" onClick={sendTestEmail} disabled={testEmail === ''}>
              Send Test Email
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={deleteOpen}>
        <DialogTitle>Delete Conference</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete?</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setDeleteOpen(false)}>
            No
          </Button>
          <Button onClick={handleDelete}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={archiveOpen}>
        <DialogTitle>Archive Conference</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to archive?</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setArchiveOpen(false)}>
            No
          </Button>
          <Button onClick={archive}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ConferencePage;
