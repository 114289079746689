import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';

const PublicLayout = () => (
  <Container>
    <Outlet />
  </Container>
);

export default PublicLayout;
