import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Stack, useTheme } from '@mui/material';
import logo from '../../lib/logo.png';
import LinkedInFollowButton from '../../components/LinkedInFollowButton';
import Box from '@mui/material/Box';

const RegisterCompletePage = () => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 10,
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cabin Sketch',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        color={'primary'}
        variant="h3"
      >
        Thank you for registering!
      </Typography>
      <Box alignItems={'center'} display={'flex'} flexDirection={'column'}>
        <Typography
          sx={{
            fontFamily: 'Cabin Sketch',
            color: theme.palette.primary.main,
          }}
          color={'primary'}
          variant="h5"
        >
          Follow us on LinkedIn!
        </Typography>
        <LinkedInFollowButton companyId="10516378" />
      </Box>
      <a href="https://sketchdev.io"><img height={75} src={logo} alt="Sketch" /></a>
    </Stack>
  );
};

export default RegisterCompletePage;
