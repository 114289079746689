import * as React from 'react';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Alert, FormGroup, Switch, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Conference } from '../../types/Conference';
import { ConferenceService } from '../../services/ConferenceService';
import { Attendee } from '../../types/Attendee';
import { AttendeeService } from '../../services/AttendeeService';
import validator from 'validator';
import LoadingOverlay from '../../components/LoadingOverlay';

const RegisterAttendeePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const [conference, setConference] = useState<Conference | null | undefined>(undefined);
  const [attendee, setAttendee] = useState<Attendee | null>(null);
  const [offerings, setOfferings] = useState<Map<string, boolean>>(new Map());
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [jobTitleError, setJobTitleError] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [companyError, setCompanyError] = useState<string | null>(null);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const c = await ConferenceService.getPublic(id);
      if (c) {
        setConference(c);
        const newOfferings = new Map<string, boolean>();
        for (const i of c.interests || []) {
          newOfferings.set(i.label, false);
        }
        setOfferings(newOfferings);
      } else {
        setConference(null);
      }
    })();
  }, [id]);

  if (conference === undefined) return <Typography>Loading</Typography>;
  if (conference === null) return <Typography>Conference Not Found</Typography>;

  const handleChange = (event: any) => {
    setOfferings((prevState) => new Map(prevState.set(event.target.name, event.target.checked)));
  };

  const register = async () => {
    setSaving(true);
    let valid = true;
    if (!attendee?.firstName) {
      setFirstNameError('First name is required');
      valid = false;
    }
    if (!attendee?.lastName) {
      setLastNameError('Last name is required');
      valid = false;
    }
    if (!attendee?.email) {
      setEmailError('Email Address is required');
      valid = false;
    } else if (!validator.isEmail(attendee.email)) {
      setEmailError('Email Address is not valid');
      valid = false;
    }
    if (!attendee?.jobTitle) {
      setJobTitleError('Job Title is required');
      valid = false;
    }
    if (attendee?.phone) {
      const phoneRegex = /^[0-9]{10,11}$/;
      if (!phoneRegex.test(attendee.phone)) {
        setPhoneError('Phone is not valid');
        valid = false;
      }
    }
    if (
      attendee?.company &&
      !validator.isURL(attendee.company, { require_valid_protocol: false })
    ) {
      setCompanyError('Company website is not valid');
      valid = false;
    }

    if (valid) {
      const selectedOfferings = [...offerings.entries()].filter(([_, value]) => value);
      const interests = (conference.interests || []).filter(
        (i) => i.label && [...selectedOfferings.values()].map(([key, _]) => key).includes(i.label),
      );
      const newAttendee = { ...attendee, confId: id, interests };

      const result = await AttendeeService.create(newAttendee);
      if (result.success) {
        navigate('/register/complete');
      } else {
        setSaveError(result.error);
      }
    }
    setSaving(false);
  };

  return (
    <Box
      sx={{
        marginTop: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      {saving && <LoadingOverlay />}
      <Typography
        sx={{
          fontFamily: 'Cabin Sketch',
          textAlign: 'center',
          marginLeft: 10,
          marginRight: 10,
          color: theme.palette.primary.main,
        }}
        color={'primary'}
        variant="h3"
        mb={3}>
        Register to WIN!
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
            onChange={(e) => setAttendee({ ...attendee, firstName: e.target.value })}
            error={firstNameError !== null}
            helperText={firstNameError}
            onFocus={() => setFirstNameError(null)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            onChange={(e) => setAttendee({ ...attendee, lastName: e.target.value })}
            error={lastNameError !== null}
            helperText={lastNameError}
            onFocus={() => setLastNameError(null)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            type="email"
            onChange={(e) => setAttendee({ ...attendee, email: e.target.value })}
            error={emailError !== null}
            helperText={emailError}
            onFocus={() => setEmailError(null)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="jobTitle"
            label="Job Title"
            name="jobTitle"
            onChange={(e) => setAttendee({ ...attendee, jobTitle: e.target.value })}
            error={jobTitleError !== null}
            helperText={jobTitleError}
            onFocus={() => setJobTitleError(null)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="phone"
            label="Phone Number"
            name="phone"
            autoComplete="phone"
            onChange={(e) => {
              const numericValue = e.target.value.replace(/\D/g, '');
              setAttendee({ ...attendee, phone: numericValue });
            }}
            error={phoneError !== null}
            helperText={phoneError}
            onFocus={() => setPhoneError(null)}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={attendee?.phone || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="company"
            label="Company Website URL"
            type="company"
            id="company"
            onChange={(e) => setAttendee({ ...attendee, company: e.target.value })}
            error={companyError !== null}
            helperText={companyError}
            onFocus={() => setCompanyError(null)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color={'primary'} mt={2} mb={2}>
            Please let us know which of the following Sketch services you would like to learn more
            about:
          </Typography>
          <FormGroup>
            {Array.from(offerings.entries()).map((entry) => {
              const [key, value] = entry;
              return (
                <FormControlLabel
                  key={key}
                  control={<Switch checked={value} onChange={handleChange} name={key} />}
                  label={key}
                />
              );
            })}
          </FormGroup>
        </Grid>
      </Grid>
      <Button variant="contained" sx={{ mt: 3, mb: 2 }} onClick={register} disabled={saving}>
        Register
      </Button>
      {saveError && (
        <Alert severity="error" variant="standard" className="alert">
          Failed to save!
        </Alert>
      )}
    </Box>
  );
};

export default RegisterAttendeePage;
