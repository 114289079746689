import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Alert } from '@mui/material';
import { AuthService } from '../../services/AuthService';

const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [saveError, setSaveError] = useState<string | null>(null);

  const handleLogin = async () => {
    if (username === '') {
      setUsernameError('Username is required');
    } else if (password === '') {
      setPasswordError('Password is required');
    } else {
      const result = await AuthService.login(username, password);

      if (result && result.success) {
        navigate('/conferences');
      } else {
        setSaveError('Failed');
      }
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h3'}>Login</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="username"
            label="Username"
            autoFocus
            error={usernameError !== null}
            helperText={usernameError}
            onFocus={() => setUsernameError(null)}
            onChange={(e) => setUsername(e.target.value)}
            value={username || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            type={'password'}
            id="password"
            label="Password"
            error={passwordError !== null}
            helperText={passwordError}
            onFocus={() => setPasswordError(null)}
            onChange={(e) => setPassword(e.target.value)}
            value={password || ''}
          />
        </Grid>
      </Grid>
      <Button onClick={handleLogin} variant="contained" sx={{ mt: 3, mb: 2 }}>
        Submit
      </Button>
      {saveError && (
        <Alert severity="error" variant="standard" className="alert">
          Failed to login! Check your username and password, and try again.
        </Alert>
      )}
    </Box>
  );
};

export default LoginPage;
