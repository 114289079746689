import React, { Dispatch, SetStateAction } from 'react';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Attendee } from '../types/Attendee';
import { AttendeeService } from '../services/AttendeeService';
import { Conference, Interest } from '../types/Conference';

const AttendeesTable = ({
  conference,
  attendees,
  setAttendees,
}: {
  conference: Conference;
  attendees: Attendee[];
  setAttendees: Dispatch<SetStateAction<Attendee[]>>;
}) => {
  const AttendeeIconButton = ({ attendee }: { attendee: Attendee }) => {
    const handleEligible = async () => {
      const newEligibility = !attendee.eligible;
      const result = await AttendeeService.eligibility({
        email: attendee.email,
        confId: attendee.confId,
        eligible: newEligibility,
      });
      if (result.success) {
        setAttendees(
          attendees.map((a) => {
            if (a.email === attendee.email) {
              return { ...a, eligible: newEligibility };
            } else {
              return a;
            }
          }),
        );
      }
    };

    return (
      <IconButton onClick={handleEligible}>
        {attendee.eligible ? <PersonIcon /> : <PersonOffIcon />}
      </IconButton>
    );
  };

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First Name', flex: 1 },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 2 },
    { field: 'company', headerName: 'Company', flex: 2 },
    {
      field: 'interests',
      headerName: 'Interests',
      flex: 2,
      sortable: false,
      valueGetter: (params) =>
        (params.row.interests || []).map((i: Interest | string) => {
          if (typeof i === 'string') {
            return i;
          } else {
            return i.label;
          }
        }),
    },
    {
      field: 'winner',
      headerName: 'Winner',
      valueGetter: (params) =>
        params.row.email && (conference.prizeWinners || []).includes(params.row.email),
      renderCell: (params) => (params.value ? <StarIcon /> : <></>),
    },
    {
      field: 'actions',
      headerName: '',
      renderCell: (params) => <AttendeeIconButton attendee={params.row} />,
      sortable: false,
    },
  ];

  return (
    <DataGrid
      rows={attendees}
      columns={columns}
      getRowId={(r) => r.email}
      getRowHeight={() => 'auto'}
    />
  );
};

export default AttendeesTable;
