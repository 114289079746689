import React, { useEffect, useState } from 'react';
import { ConferenceService } from '../../services/ConferenceService';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import { Conference } from '../../types/Conference';
import { QRCode } from 'react-qrcode-logo';
import circleLogo from '../../lib/circle_logo.png';
import { Stack, useTheme } from '@mui/material';

const ConferenceLandingPage = () => {
  const theme = useTheme();
  const { id } = useParams();
  const [conference, setConference] = useState<Conference | null | undefined>(undefined);

  const getUrl = () => {
    const { pathname, href } = window.location;
    return href.replace(pathname, '/register/' + id);
  };

  useEffect(() => {
    (async () => {
      const c = await ConferenceService.getPublic(id);
      if (c) {
        setConference(c);
      } else {
        setConference(null);
      }
    })();
  }, [id]);

  if (conference === undefined) return <Typography>Loading</Typography>;
  if (conference === null) return <Typography>Conference Not Found</Typography>;

  return (
    <Stack
      spacing={2}
      sx={{
        marginTop: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cabin Sketch',
          textAlign: 'center',
          marginLeft: 10,
          marginRight: 10,
          color: theme.palette.primary.main,
        }}
        color={'primary'}
        variant="h3"
      >
        Scan code to register{conference.prizeName ? ' for prize' : ''}!
      </Typography>
      <Typography variant="h5" color={'primary'}>
        {conference.prizeName}
      </Typography>
      {conference.prizeImgUrl && (
        <img height={200} src={conference.prizeImgUrl} alt={conference.prizeName} />
      )}
      {conference.prizeDesc && (
        <Typography variant="body1" color={'primary'}>
          {conference.prizeDesc}
        </Typography>
      )}
      <QRCode
        fgColor={theme.palette.primary.main}
        value={getUrl()}
        size={250}
        logoImage={circleLogo}
        eyeColor={theme.palette.secondary.main}
      />
    </Stack>
  );
};

export default ConferenceLandingPage;
