import { createContext, ReactElement, useContext, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

type Props = {
  children: ReactElement;
};

const AuthContext = createContext<any>(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState({});

  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>;
};

export const RequireAuth = () => {
  const [cookies] = useCookies(['authenticated']);
  const location = useLocation();

  if (!cookies.authenticated) {
    return <Navigate to={{ pathname: '/unauthorized' }} state={{ from: location }} replace />;
  }

  return <Outlet />;
};
